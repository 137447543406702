

















import { Vue, Component } from 'vue-property-decorator';
import {i18n} from '@/main';
import { getStatic } from '@/utilities/static';

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.404.title')
    };
  },
})
export default class Error404 extends Vue {
  private ErrorIcon = getStatic('icons/errors/error-icon.svg');
  private Illustration = getStatic('icons/errors/404.svg');
}
